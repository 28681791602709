import {Button, Flex} from "antd";
import {IconEye} from "@tabler/icons-react";
import '../styles/components/FixedSaveButton.sass'
import {blue} from "@ant-design/colors";

interface FixedSaveButtonProps {
    onClick: () => void;
    onClickPreview: () => void,
    disabled?: boolean;
    loading?: boolean;
    disabledText?: string;
    text?: string;
}

const FixedSaveButton = ({onClick, onClickPreview, disabled, loading, disabledText, text= 'Pubblica le modifiche'}: FixedSaveButtonProps) => {
    return (
        <Flex gap={5}
              style={{position: "sticky", bottom: 0, backgroundColor: 'white', marginTop: 50, paddingBottom: 15}}>
            <Button className={'show-xl'} onClick={onClickPreview} size={'large'}><IconEye/>anteprima</Button>
            <Button loading={loading} onClick={onClick} disabled={disabled} style={{flex: 1}} type={'primary'}
                    size={'large'}>
                {
                    !disabled &&
                    <div style={{width: 8, height: 8, backgroundColor: blue[4], borderRadius: 10}}/>
                }
                {disabled && disabledText ? disabledText : text}
            </Button>
        </Flex>
    );
}
export default FixedSaveButton;
