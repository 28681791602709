import {PriceListItem, PriceListItemData, PriceListItemTypes} from "../../types/PriceListTypes";
import {AnimatePresence, motion} from "framer-motion";
import PriceListItemCard from "./PriceListItemCard";
import AddItemButtons from "./AddItemButtons";
import Wrapper from "../Wrapper";
import {COLORS} from "../../utils/colors";
import {Badge, Button, Flex} from "antd";
import {IconEdit, IconHttpDelete, IconTrash} from "@tabler/icons-react";
import {green, red} from "@ant-design/colors";
import {bucketUrl} from "../../utils/urls";

interface PriceListItemWrapperProps {
}

const ItemCard = ({item, focused, setFocused, addBlock, onEdit, onRemove}: {
    item: PriceListItem,
    focused: string,
    setFocused: (focused: string) => void,
    addBlock: (id: string,
               position: 'addBelow' | 'addAbove',
               type: PriceListItemTypes) => void,
    onEdit: (formItemOptions: {
        action: 'edit' | 'addBelow' | 'addAbove',
        fromId: string,
        item: PriceListItem
    }) => void,
    onRemove: (itemToRemove: PriceListItem) => void,
}) => {
    return (
        <div onDoubleClick={() => onEdit({action: 'edit', fromId: '', item})} onClick={(e) => { setFocused(item.id)} }
             style={{cursor: 'pointer'}}>
            <motion.div key={item.id} initial={{opacity: 0, x: -30}} animate={{opacity: 1, x: 0}}
                        exit={{opacity: 0, x: -20,}}
                        style={{marginTop: 5}}>
                <AddItemButtons open={focused === item.id} onClick={(type) => addBlock(item.id, 'addAbove', type)}/>
                <Wrapper style={{
                    borderColor: focused === item.id ? 'black' : COLORS.lightGray,
                    padding: 15,
                    backgroundColor: item.data.available === false ? COLORS.veryLightGray : 'transparent'
                }}>
                    {item.data.available === false &&
                        <p
                            style={{
                                padding: "3px 5px",
                                backgroundColor: red[1],
                                borderRadius: 5,
                                color: red[6],
                                fontSize: 12,
                                fontWeight: 600,
                                width: "fit-content",
                                marginBottom: 10
                            }}
                        >
                            Nascosto
                        </p>
                    }
                    {item.type === 'section' ?
                        <Flex align={'center'} gap={6}>
                            {item.data.imagePath ? <img style={{borderRadius: 5}} width={80} src={bucketUrl + item.data.imagePath}/> : <div></div>}
                            <p style={{padding: 0, border: 'none'}}
                               className={`section-title`}>{item.data.name}</p>
                        </Flex>
                        :
                        <PriceListItemCard focusedId={focused} item={item} setFocused={setFocused} theme={'elegant'}/>
                    }
                    <Flex gap={2} style={{marginTop: 5}}>
                        {item.data.allergens?.map((item) => (
                            <div key={item}>
                                <img style={{width: 18}} src={`./allergens-icons/${item}.svg`}/>
                            </div>
                        ))}
                    </Flex>
                    <AnimatePresence>
                        {focused === item.id &&
                            <motion.div initial={{height: 0, opacity: 0}} animate={{height: 35, opacity: 1}}>
                                <Flex align={'center'} justify={'space-between'} style={{
                                    marginTop: 10,
                                    borderTop: `1px solid ${COLORS.lightGray}`,
                                    paddingTop: 10
                                }}>
                                    <Button
                                        size={'small'} onClick={() => onRemove(item)}><IconTrash
                                        size={16}/></Button>
                                    <Button
                                        type={'primary'}
                                        size={'small'}
                                        onClick={() => onEdit({action: 'edit', fromId: '', item})}><IconEdit
                                        size={16}/>Modifica</Button>
                                </Flex>
                            </motion.div>
                        }
                    </AnimatePresence>
                </Wrapper>
                <AddItemButtons onClick={(type) => addBlock(item.id, 'addBelow', type)} down
                                open={focused === item.id}/>
            </motion.div>
        </div>
    );
}
export default ItemCard;
